<template>
   <div>
     <router-view/>
   </div>
</template>

<script>
export default {
  name: "index",
}
</script>

<style scoped>

</style>